import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Tooltip } from 'bootstrap';
import { SelectItem, ThemeService } from 'diflexmo-angular-design';
import { AuthenticationService } from './core/auth/templates-mgt/services/authentication.service';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TenantService } from './core/services/api/tenant-api.service';
import { Tenant } from './shared/models/tenant.model';
import { TenantSynchronizationService } from './core/services/tenant-synchronization.service';
import { UserPermissionService } from './core/auth/templates-mgt/services/user.permissions.service';
import { UsersService } from './core/services/api/users-api.service';

@Component({
  selector: 'dfm-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(private themeService: ThemeService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthenticationService,
    private tenantService: TenantService,
    private tenantSynchronizationService: TenantSynchronizationService,
    private permissionsService: UserPermissionService,
    private apiUsersService: UsersService,
    private router: Router) { }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.msalService.loginRedirect({ prompt: 'select_account', scopes: [environment.authentication.templatesMgtApiAuthScope] });
      } else if (!this.router.url.includes('preventRedirect')) {
        this.authService.checkAndSetActiveAccount();
        this.tenantService.getTenants()
          .pipe(
            take(1),
            tap((tenants: Tenant[]) => {
              this.tenantSynchronizationService.emitAllowedTenantsLoaded(tenants);
            }),
            tap((selectableTenants) => {
              const tenant = selectableTenants[0];
              this.tenantSynchronizationService.emitTenantChangedEvent(tenant)
            }),
            switchMap(() => this.apiUsersService.getPermissions()),
            tap((permissions: string[]) => this.permissionsService.emitPermissionsChanged(permissions))
          ).subscribe();
      }
    });

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((e) => new Tooltip(e));
    this.themeService.init();
  }

  ngOnDestroy(): void {
    this.themeService.destroy();
  }
}
