import { SilentRequest } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private msalService: MsalService) { }

  private isInitialized = false;

  private initializeMsal(): Observable<void> {
    if (this.isInitialized) {
      return of(undefined);
    }

    return from(this.msalService.initialize()).pipe(
      tap(() => (this.isInitialized = true))
    );
  }

  //request templates mgt token
  public getTemplatesMgtToken(): Observable<string> {
    const request: SilentRequest = {
      scopes: [environment.authentication.templatesMgtApiAuthScope],
      redirectUri: environment.authentication.redirectUrl,
    };
    return this.initializeMsal().pipe(
      switchMap(() =>
        this.msalService.acquireTokenSilent(request).pipe(
          map((result) => result.accessToken)
        )
      )
    );
  }
}
