import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { InputDropdownComponent, NavigationItem, SelectItem } from 'diflexmo-angular-design';
import { filter, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DestroyableComponent } from 'src/app/shared/components/destroyable.component';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { TenantSynchronizationService } from '../../services/tenant-synchronization.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UsersService } from '../../services/api/users-api.service';
import { UserPermissionService } from '../../auth/templates-mgt/services/user.permissions.service';

@Component({
  selector: 'dfm-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: [],
})
export class BaseLayoutComponent extends DestroyableComponent implements AfterViewInit, OnInit {
  private anchorTag!: any;

  public navigationItems: NavigationItem[] = [
    new NavigationItem('Back to fordesk', 'home-03', './', true),
    new NavigationItem('Templates', 'home-03', '/templates', false),
  ];

  public selectableTenants: SelectItem[] = [];

  @ViewChild('tenants') tenantsSelector: InputDropdownComponent;

  public isLoaderActive$$ = new Subject<boolean>();

  constructor(
    public loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    private fb: FormBuilder,
    private apiUsersService: UsersService,
    private permissionsService: UserPermissionService,
    private tenantSynchronizationService: TenantSynchronizationService,
  ) {
    super();
  }
  public form: FormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      tenant: [null],
    });

    this.form.controls['tenant'].valueChanges.subscribe((selectedTenantId) => {
      const tenant = this.tenantSynchronizationService.getCurrentAllowedTenants.find(x => x.id.toString() == selectedTenantId)
      this.tenantSynchronizationService.emitTenantChangedEvent(tenant)
    })
  }

  public ngAfterViewInit(): void {
    this.tenantSynchronizationService.getAllowedTenantsObservable$
      .pipe(filter(x => !!x && x.length > 0),
        map((tenants) =>
          tenants.map(
            (tenant) => new SelectItem(tenant.name, tenant.id.toString()),
          ),
        ), tap((selectableTenants) => {
          this.selectableTenants = selectableTenants;
          this.tenantsSelector.selectItem(selectableTenants[0])
        }),
        switchMap(() => this.apiUsersService.getPermissions()),
        tap((permissions: string[]) => this.permissionsService.emitPermissionsChanged(permissions))
        , takeUntil(this.destroy$$))
      .subscribe();

    this.loaderService.isActive$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        this.isLoaderActive$$.next(value);
        this.cdr.detectChanges();
      },
    });
    this.anchorTag = this.el.nativeElement.getElementsByTagName('a');
    if (this.anchorTag?.length) {
      this.renderer.listen(this.anchorTag?.[0], 'click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        window.location.href = environment.fordeskUrl;
      });
    }
    if (this.anchorTag?.length > 2) {
      this.renderer.listen(this.anchorTag?.[2], 'click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        window.location.href = environment.fordeskUrl;
      });
    }
  }
}
