import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantPermissions } from '../enums/tenant-permissions';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  private userPermissions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor() { }

  public get getPermissions(): string[] {
    return this.userPermissions$.value;
  }

  public emitPermissionsChanged(permissions: string[]) {
    this.userPermissions$.next(permissions)
  }

  public getPermissionsObservable$(): Observable<string[]> {
    return this.userPermissions$.asObservable();
  }

  public userhasTemplatesMgtAdminPermission$(): Observable<boolean> {
    return of(this.userPermissions$.value.includes(TenantPermissions.CanPerformTemplatesMgtAdmin))
  }
}
