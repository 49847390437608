import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Registering SyncFusion license key
registerLicense(environment.syncFusionLicenseKey);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    console.log(err);
  });
