import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { environment } from "../../../../../environments/environment";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal
export class AuthConfig {
	static readonly protectedApis: ProtectedApi[] = [
		{
			url: environment.apiHostUrl,
			scope: environment.authentication.templatesMgtApiAuthScope,
		},
	];

	static readonly authFlow: string = environment.authentication.authFlow;

	static readonly authClientId: string = environment.authentication.authClientId;

	static readonly tenant: string = environment.authentication.tenant;
}

export const MSALConfig: Configuration = {
	auth: {
		clientId: AuthConfig.authClientId,
		authority: `https://${AuthConfig.tenant}.b2clogin.com/${AuthConfig.tenant}.onmicrosoft.com/${AuthConfig.authFlow}`,
		knownAuthorities: [`${AuthConfig.tenant}.b2clogin.com`],
		redirectUri: environment.authentication.redirectUrl,
		navigateToLoginRequestUrl: false,
		postLogoutRedirectUri: environment.authentication.postLogoutRedirectUri,
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage,
		storeAuthStateInCookie: isIE,
	},
	system: {
		loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },

	},
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(logLevel, message);
}

export class ProtectedApi {
	url: string = '';

	scope: string = '';
}
