
export const environment = {
  authentication:{
    templatesMgtApiAuthScope:'#{TemplateManagementUI.authentication.templatesMgtApiAuthScope}#',
    authFlow:'#{TemplateManagementUI.authentication.authFlow}#',

    redirectUrl: `${window.location.origin}`,
    tenant:'#{TemplateManagementUI.authentication.tenant}#',
    authClientId:'#{TemplateManagementUI.authentication.authClientId}#',

    postLogoutRedirectUri: 'logout',
  },

  production: true,
  syncFusionLicenseKey: '#{SyncfusionAspNetCoreLicenseKey}#',
  apiHostUrl: '#{TemplateManagementUI.Api}#',
  fordeskUrl: '#{TemplateManagementUI.FordeskUrl}#',
  identityApiConfig: {
    host: '#{TemplateManagementUI.Authentication.IdentityApi}#',
    baseUrl: 'api',
  },
};
