import { Injectable } from '@angular/core';

import { filter, Observable, switchMap, take } from 'rxjs';
import { UserPermissionService } from 'src/app/core/auth/templates-mgt/services/user.permissions.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalAuthFilter {
  constructor(private permissionsService: UserPermissionService) { }

  canActivateChild(): Observable<boolean> {
    return this.can();
  }

  canActivate(): Observable<boolean> {
    return this.can();
  }

  private can(): Observable<boolean> {
    return this.permissionsService
      .getPermissionsObservable$()
      .pipe(
        filter(x => !!x && x?.length > 0), switchMap(() => this.permissionsService.userhasTemplatesMgtAdminPermission$()), take(1))
  }
}
