import { NgModule } from '@angular/core';
import { DfmDesignSystemModule } from 'diflexmo-angular-design';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    DfmDesignSystemModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [AppRoutingModule],
})
export class CoreModule {}
