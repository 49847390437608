import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Tenant } from 'src/app/shared/models/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantSynchronizationService {
  private TenantSubject$ = new BehaviorSubject<Tenant | null>(null)
  private allowedTenantsSunject$: BehaviorSubject<Tenant[] | null> = new BehaviorSubject<Tenant[]>(null);

  public emitAllowedTenantsLoaded(tenants: Tenant[]) {
    this.allowedTenantsSunject$.next(tenants);
  }

  public get getCurrentAllowedTenants(): Tenant[] | null {
    return this.allowedTenantsSunject$.value;
  }

  public get getAllowedTenantsObservable$(): Observable<Tenant[]> {
    return this.allowedTenantsSunject$.asObservable();
  }

  public get getCurrentTenantData(): Tenant | null {
    return this.TenantSubject$.value;
  }

  public get getCurrentTenantId(): string | undefined {
    return this.TenantSubject$.value?.id;
  }

  public getTenantObservable$(): Observable<Tenant> {
    return this.TenantSubject$.asObservable();
  }

  public emitTenantChangedEvent(tenatn: Tenant) {
    this.TenantSubject$.next(tenatn);
  }
}
