import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DfmDesignSystemModule, provideDesignSystem } from 'diflexmo-angular-design';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/http/error.interceptor';
import { SharedModule } from './shared/shared.module';
import { MsalGuard, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AuthConfig, MSALConfig } from './core/auth/templates-mgt/configuration/auth.config';
import { DfmInterceptor } from './core/auth/templates-mgt/interceptors/dfm.interceptor';
import { UserInitialisationInterceptor } from './core/auth/templates-mgt/interceptors/user-initialisation.interceptor';
import { GlobalAuthFilter } from './core/auth/templates-mgt/guards/global-auth-filter.guard';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    DfmDesignSystemModule,
    MsalModule.forRoot(
      new PublicClientApplication(MSALConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(AuthConfig.protectedApis.map((apis) => [apis.url, [apis.scope]])),
      },
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    FormsModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    MsalGuard,
    GlobalAuthFilter,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DfmInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInitialisationInterceptor,
      multi: true
    },
    provideDesignSystem(),
  ],
})
export class AppModule { }
