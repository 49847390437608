import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader.service';
import { Tenant } from 'src/app/shared/models/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private http: HttpClient, private loaderSvc: LoaderService) {}

  public getTenants(): Observable<Tenant[]> {
    this.loaderSvc.activate();
    return this.http
      .get<Tenant[]>(`${environment.apiHostUrl}/api/tenants`)
      .pipe(tap(() => this.loaderSvc.deactivate()));
  }
}
