import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AuthUser } from 'src/app/shared/models/user.model';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public authUser$: BehaviorSubject<AuthUser | undefined> = new BehaviorSubject<AuthUser | undefined>(undefined);

  constructor(
    private msalService: MsalService) { }

  public msalUser$ = new ReplaySubject<AccountInfo>(1);

  public getCurrentUser(): AuthUser | undefined {
    return this.authUser$.value;
  }

  public initializeUser(): Observable<boolean> {
    const user = this.msalService.instance.getActiveAccount();
    if (!user) {
      return of(true);
    }

    this.msalUser$.next(user);

    return of(true);
  }

  public logout() {
    localStorage.clear();
    this.msalService.logout();
  }
}
